/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 *{
 font-family: 'Poppins', sans-serif;
 }
 
 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
    min-height: calc(100vh - 53px);
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 20px;
  font-size: 19px;
  color: #A4A6B3;  
}

#sidebar-wrapper .list-group {
  width: 240px;
    min-height: calc(100vh - 122px);
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

.powiadomienie .card-link{
    align-self: center!important;
}

.breadcrumb-item img{
	margin: 0 10px 0 10px;	
}
@media (min-width: 768px) {
	.inspektor-komponenty .card{
        max-width: 33.33%;
        min-width: 200px;
    }
  #sidebar-wrapper {
    margin-left: 0;
	width: 320px;
  }
  #sidebar-wrapper .list-group {
	width: 260px;
    min-height: calc(100vh - 122px);
	}
	.fixedmenu{
		position: fixed;
		width: 250px;
		overflow: hidden;
	}

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -20rem;
  }
}

fieldset{
	width: 100% !important;
}

.fordesktop{
	display: block;
}

.notfordesktop{
	display: none;
}

@media only screen and (orientation: landscape) {
	.menu-burger {
	  display: block !important;
	}
}

@media (max-width: 1140px) {
	.col-6 .btn-sm{
		width: 100%;
		margin: 5px 0 !important;
	}
}

@media (max-width: 768px) {
	.component-state{
        position: static !important;
        display: block !important;
        margin: 10px auto;
    }
    .inspektor-komponenty .card{
        min-width: 90%;
        margin-top: 20px;
    }
	.komentarz-czynnosci, .komponenty-czynnosci{
		display: none;	
	}
	.breadcrumb li:first-child{
		margin: 0 !important;
		width:100%;
		text-align: center;
	}
	.breadcrumb-item+.breadcrumb-item::before{
		content: '';
		padding: 0;
	}
	.breadcrumb-item+.breadcrumb-item {
		padding-left: 0;
	}
	.notfordesktop{
		display: block;
	}
	.fordesktop{
		display: none;
	}
	#data-zlecen{
		width:100%!important;
	}
	.elemencikprzed:before {
		width: 100px !important;
	}
	.menu-burger{
		display: block!important;
	}
	.menu-burger:focus{
		box-shadow: 0!important;
	}
	.bg-dark {
		width: 100vw;
	}
	#sidebar-wrapper{
		margin-left: -100vw;
	}
	.zlecenie-przyciski{
		margin: 0 !important;
		border: 0 !important;
	}
	.zlecenie-przyciski button{
		width: 100% !important;
		margin: 20px 0 0 !important;
	}
		.col-6 .btn-sm img{
		display: none;
	}
	
	.btns-filtry{
		justify-content: space-between!important;
		padding-right: 5px!important;
	}
	
	.table-responsive .btn {
		width: 100%;
		margin: 4px 0!important;
	}
	.paginacja {
		justify-content: center!important;
	}
	.input-group-append button.bgkolor2{
		margin:0 !important;
	}
	.finishedTask {
		width:100%;
		padding: 10px 0;
		text-align: center;
	}
	.card-header{
		text-align: center;
	}
	.has-float-label label, .has-float-label > span{
	    top: -2.5em;
		text-align: center;
	}
	.powiadomienie .card-link{
		margin: 10px auto 0;
	}
	.powiadomienie{
	flex-wrap: wrap;
	}
	
	#zlecenieszczegol tr th{
		font-size: 12px;
	}
	
	#zlecenieszczegol tr th:nth-child(3),
	#zlecenieszczegol tr td:nth-child(3){
		padding-left:0 !important;
		padding-right:0 !important;
	}
	
	#podglad tr td:nth-child(3),
	#podglad tr th:nth-child(3),
	#podglad .btn-outline-primary img,
	#zlecenia tr td:nth-child(3),
	#zlecenia tr th:nth-child(3),
	#uzytkownicy tr th:nth-child(4),
	#uzytkownicy tr td:nth-child(4),
	#uzytkownicy tr th:nth-child(6),
	#uzytkownicy tr td:nth-child(6),
	#uzytkownicy tr th:nth-child(1),
	#uzytkownicy tr td:nth-child(1),
	#klienci tr th:nth-child(1),
	#klienci tr td:nth-child(1),
	#klienci tr th:nth-child(4),
	#klienci tr td:nth-child(4),
	#urzadzenia tr th:nth-child(6),
	#urzadzenia tr td:nth-child(6),
	#zlecenieszczegol tr th:nth-child(2),
	#zlecenieszczegol tr td:nth-child(2)
	{
		display: none;
	}
	
	
	#zlecenia .kolko-40,
	#zleceniaadmina .kolko-40,
	#zlecenieszczegol .kolko-40
	{
		width:40px!important;
	}
	
	.card-body h3.text-right,
    .card-body h3.text-right span,
    .card-body h3.text-right li,
    .card-body h3.text-right li span,
    .card-body h3.text-right li a,
    .card-body h3 span.text-left{
        text-align: center !important;
        width: 100% !important;
        display: block;
    }
    .card-body h3 span.font-weight-light{
        margin-bottom: 10px;
        margin-top: 20px;
    }
}
	#podglad tr td:nth-child(2),
	#zlecenia tr td:nth-child(2),
	#zlecenia tr td:nth-child(6),
	#zleceniaadmina tr th:nth-child(6),
	#zleceniaadmina tr td:nth-child(6),	
	#zleceniaadmina tr th:nth-child(2),
	#zleceniaadmina tr td:nth-child(2)	
	{
		white-space: nowrap;
	}
/*------------------------------------style fixlift -----------------------*/

.pos-relative {
	position:relative;
}

.pos-absolute {
	position:absolute;
}

.kolor{
	color: #BF1E2E;
}

a, .btn-link{
	color:#000;
}

.btn-link{
	white-space: nowrap;
}

.bgkolor {
	background-color: #BF1E2E;
	color: #fff;
}
.bgkolor2{
	background-color: #0E4DA4;
	color: #fff;
}

.borderkolor{
	border-color: #BF1E2E;
}

button.bgkolor{
	padding: 10px 20px;
}

.btn-jasny-outline{
	padding: 10px 20px;
	color: #fff;
	border-color: rgba(255,255,255,.6);
}

.btn-jasny{
	padding: 10px 20px;
	color:#4d4d4d;
	background-color: rgba(255,255,255,.8);

}
#save{
	position:absolute;
	left:50%;
	background-color: #fff;
	padding: 6px 0 10px;
	top:0;
}

.zlecenie-przyciski div{
	border-radius: 4px;
}

#save:before {
    content: '';
    width: 70px;
    height: 0;
    border-top: 52px solid #fff;
    border-right: 30px solid transparent;
    position: absolute;
    right: -50px;
	top:-3px;
    z-index: -1;
}
#save:after {
    content: '';
    width: 70px;
    height: 0;
    border-top: 52px solid #fff;
    border-left: 30px solid transparent;
    position: absolute;
	top:-3px;
    right: 112px;
    z-index: -1;
}
#save .spinner-border {
    top: 5px;
}


button.btn-jasny:hover,
button.btn-jasny:not(:disabled):not(.disabled).active, button.btn-jasny:not(:disabled):not(.disabled):active{
	background-color: #fff;
	color: #4d4d4d;
}
button.bgkolor:hover,
button.bgkolor:not(:disabled):not(.disabled).active, button.bgkolor:not(:disabled):not(.disabled):active{
    color: #fff;
    background-color: #9e0a19;
    border-color: #9e0a19;
}
button.bgkolor img,
button.btn-jasny-outline img,
button.btn-jasny img{
	margin-left: 5px;
}

.form-group {
	position: relative;
	margin-bottom: 2rem;
}

.form-control:focus{
	box-shadow: none;
}
.input-group .has-float-label .form-control:focus, .input-group .has-float-label .form-control:active{
	border-color: #0E4DA4;
}


.has-float-label {
  display: block;
  position: relative;
}
.has-float-label label, .has-float-label > span {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
  top: -.5em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}
.has-float-label label::after, .has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  background: white;
  height: 2px;
  top: 50%;
  left: -.2em;
  right: -.2em;
  z-index: -1;
}
.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}
.has-float-label .form-control::-moz-placeholder {
  opacity: 1;
  transition: all .2s;
}
.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1;
  transition: all .2s;
}
.has-float-label .form-control::placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 100%;
  opacity: .5;
  top: 1.2em;
}

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.input-group .has-float-label .form-control {
  width: 100%;
  border-radius: 8px;
    border: 2px solid rgba(0,0,0,.05);
}






.m--1{
	margin-left: -1px!important;
}
.mt--5{
	margin-top: -5px;
}
.form-control{
	border-radius: 8px;
}
body { padding-right: 0 !important; }

.modal-open {
    overflow: scroll;
	padding-right: 0!important;
}
.modal-footer{
	border: 0;
}
.btn.focus, .btn:focus{
	box-shadow: none !important;
}
/*--------------------dekor ----------------*/
.elemencikprzed:before {
    content: '';
    width: 85px;
    height: 0;
    border-bottom: 52px solid #fff;
    border-right: 30px solid transparent;
    position: absolute;
    left: 0;
    top: -50px;
    z-index: 99;
}
.elemencikpo:after {
    content: '';
    width: 85px;
    height: 0;
    border-top: 52px solid #fff;
    border-left: 30px solid transparent;
    position: absolute;
    right: 0;
    bottom: -50px;
    z-index: 99;
}
.panel .elemencikprzed:before {
    border-bottom: 52px solid #343a40;
	top: 1px;
}
/*---------------- ikony kontaktu na dole --------------*/
.icon-telefon {
  background-image: url(../gfx/telefon.svg);
}
.icon-mail {
  background-image: url(../gfx/mail.svg);
}

.icon {
  display: inline-block;
  opacity: .6;
  width: 42px;
  height: 42px;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon:hover,
.icon:focus {
  opacity: 1;
}

.kontaktzbaza{
	margin: 30px auto;
	color: #c0c0c0;
	width: 120px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 60px);
}

.kontaktzbaza h4, .kontakthead h4{
	width: 100%;
	color: #c0c0c0;
	font-size: 14px;
	text-align: center;
    border-bottom: 1px solid rgb(0,0,0,.2);
	padding-bottom: 10px;
	margin-bottom: 10px;
}

/***************** Panel administracyjny ******************/

body{
	background-image: url('../gfx/fixlift-bg.jpg');
	background-position: center center;
    background-size: cover;
	height: 100%;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
	font-weight: 800;
}

h3{
	font-size:18px;
}

h4{
	font-size:13px;
}

.container-fluid{
	padding:0 30px;
}

#wrapper{
	background-color:#fff;
}
/**************************************************************sidebar z menu*************************/
.menu-burger img{
	width: 30px;
	height: 25px !important;
	margin-left: 0!important;
}
.menu-burger{
	margin-left: 0!important;
    position: absolute;
    z-index: 100;
    top: 15px;
    left: 15px;
    padding: 0;
	display:none;
}
.top-logo{
	width: 100%;
	padding: 12px;
}
.top-logo .logo{
	width: 68px;
    margin: 0 auto;
	display: block;
}
.centruj{
    display:block;
	margin: 0 auto;
}
.list-group-item img{
	margin-right: 20px;
}

.list-group-item-action{
	color: rgb(221 226 255);
	opacity: 0.4;
	border-left: 4px solid #343a40 !important;
}
.list-group-item-action:focus, .list-group-item-action:hover, .menu-active{
	opacity: 1;
	color: rgb(221 226 255);
	border-left: 4px solid #BF1E2E !important;
}
.menu-wyloguj{
	margin-top:auto;
 	cursor:pointer;
}
.list-group-flush>.list-group-item:last-child {
    border: 1px solid rgba(0,0,0,.125);
}
/*****************************************/
.usercolor-container{
	padding: 5px;
	border: 2px solid #dedede;
	width: 38px;
	height: 38px;
	margin-left: 10px;
}

.table td .usercolor-container, .metryka .usercolor-container, .smallSpot{
	width: 20px;
	height: 20px;
	margin: 0 auto;
	padding: 2px;
}

.table {
	margin-bottom:0 !important;
}

.usercolor{
	background-color: #BF1E2E;
	width: 100%;
	height: 100%;
}
.witaj{
	padding: 20px 0;
	border-bottom: 1px solid #EBEFF2;
}

button.btn img{
	margin-left: 2px;
    height: 19px;
}

.wstecz img{
    margin-top: -2px;
    margin-right: 10px;
}

#czas-zlecenia span,
#id-urzadzenia span{
	font-weight: 400;
}
.kolko-40{
	height: 40px;
    width: 40px;
	font-weight: 900;
}

.kolko-30{
	height: 30px;
    width: 30px;
	font-weight: 900;
}

button.btn.btn-sm.btn-outline-success.m-0.rounded-circle.kolko-40 img {
	opacity: 0;
}

button.btn.btn-sm.btn-outline-success.m-0.rounded-circle.kolko-40:hover img {
    opacity: 1;
}

.table-success .btn-outline-secondary{
	background: #fff;
}
/*-------------podglad------------*/
.card h1{
		text-align:center;
		font-weight:100;
		font-size: 60px;
		margin-top: 10px;
}
.card .card-title{
	text-align: center;
	font-weight:300;
	margin-bottom: 0;
	font-size: 18px;
}

.dzisiaj-wykonano{
		color: #2ED47A;
}

.zlecen-jutro{
	color: #0E4DA4;
}
.card-header{
cursor: pointer;
}

/*------------------tabelki----------------*/
.table td, .table th, .table thead th{
	vertical-align: middle;
}
.ramkatabeli{
	border-radius: 8px;
	border: 1px solid #dee2e6;
}
.table td{
	text-align: center;
}
.tabela-left td{
	text-align: left;
}
.table thead th{
	color: #9FA2B4;
	font-weight: 500;
}

.rounded-10{
	border-radius: 10px;
}


.rounded-5{
	border-radius: 10px !important;
}

.akcja{
	margin: 0 5px;
	cursor:pointer;
}

.crud-role{
	color: #9FA2B4;
	font-size: 12px;
    line-height: 18px;
    padding-top: 14px;
}

.crud-controls{
line-height: 18px;
}
.ikonki tr td:last-child{
	width: 150px;
	white-space: nowrap;
	text-align: center;
}
.sep-top{
	border-top: 1px solid #EBEFF2;
	padding-top: 20px;
}
.spinner-border{
    position: relative;
    top: -1px;
    color: #bf1e2e;
}
.btns-filtry{
	justify-content: flex-end;
	padding-right: 5px !important;
}
.border-bottom{
    border-bottom: 1px solid #EBEFF2;
}
.mw-200{
	max-width: 200px;
}
.tabela-umowy, .tabela-klienci, .tabela-kosztorys{
	font-size:14px;
}

.tabela-umowy td:nth-child(8){
	font-size:12px;
	max-width: 250px !important;
}
.tabela-umowy td:nth-child(4),
.tabela-umowy td:nth-child(5),
.tabela-zlecenia thead th{
	white-space:nowrap;
}


input.autocomplete{
	border-radius: 8px 8px 0 0 !important;
}
ul.autocomplete{
	border-radius: 0 0 8px 8px !important;
	z-index: 10;
	box-shadow: 3px 5px 6px rgba(0,0,0,.05);
}
ul.autocomplete li {
	cursor: pointer;
}
ul.autocomplete li:hover {
	background: rgb(230,230,230)
}
.input-group input.autocomplete{
	border-radius: 8px 0 0 0 !important;
}
.input-group button.autocomplete{
	border-radius: 0 8px 0 0 !important;
}
#galeriaurzadzenia .carousel-item{
	max-height: 470px;
	height: 470px;
}
#galeriaurzadzenia .carousel-item img{
position: absolute;
    left: -100%;
    right: -100%;
    top: -100%;
    bottom: -100%;
    margin: auto; 
    height: auto;
    width: auto;
}

/*---------paginacja-------*/
.pagination{
    justify-content: flex-end;
	margin-right: 15px;
}
.paginacja .custom-select:focus {
	border-color: #80bdff;
	outline: 0;
	box-shadow: none;
}
.page-item {
	margin-right:8px;
}
.page-link{
  border-radius: 8px !important;
	border: 0;
}
.page-link:focus {
	z-index: 3;
	outline: 0;
	box-shadow: none;
}
#perpageselect{
	width: 70px;
    border: 0;
}

/**---------------formularze dodawania-------------***/
.dodawanie label, .label{
	color: #818E9B;
	font-size: 11px;
}
.custom-control-label{
	color: #000 !important;
	font-size: 15px !important;	
}
.top-30{
	position: relative;
    top: -30px;
}
.relative{
	position: relative;
}
.absolute{
	position: absolute;
}
.right0{
	right: 0;
}
.dodane-zdjecie{
	width: 30px;
	height: 30px;
	border-radius: 3px;
	margin-left: 3px;
	float: left;
}
.galeria-fotka .usun{
	position: absolute;
	display: none;
	margin: 6px 0 0 9px;
}

.galeria-fotka:hover .dodane-zdjecie{
	opacity:.2;
}

.galeria-fotka:hover .usun{
	display: block;
}

.magazyn-prywatny{
	width: 30px;
	height: 30px;
	margin-right: 10px;
	position: relative;
	top: -3px;
}
.tabela-mm td ul, .clearListStyle{
	list-style: none;
	margin-bottom:0;
	padding-left: 0;
}
td.vtop {
    vertical-align: top;
}
td.vtop ul{
	list-style: none;
	margin-bottom:0;
	padding-left: 0;
}
.modal-body label{
	font-size: 12px;
	color: #818E9B;
}

/*----------------- czerwona ramka gdy błąd formularza - bledne haslo + klasa .error ----------*/
.input-group .error .form-control:focus, .input-group .error .form-control:active, input.error{
	border-color: #BF1E2E;
}
.powiadomienie {
  width: 100%;
  border-radius: 8px;
  border-color: #BF1E2E;
    height: auto !important;
}
.error > span{
color: #BF1E2E;
}
.error .form-control, .error .custom-select{
    border: 1px solid #bf1e2e;
	border-color: #bf1e2e !important;
}

.errormsg{
	display: block;
	font-size: 10px;
	color: #BF1E2E;
	position: absolute;
	bottom:-20px;
}

.errormsg-activity-completed {
	display: block;
	font-size: 10px;
	color: #BF1E2E;
	position: absolute;
}

/*--------------------------------------------------------------------------------------------*/
a.list-group-item img {
    width: 20px;
}

.card{
    margin-bottom: 10px;
}

.input-group-append button {
	width: 100px;
}

.custom-select{
	border-radius:8px;
}

.float-head{
	background: #fff;
	position: relative;
	top:-12px;
    margin-right: auto;
    padding: 0 5px;
}

.tabela-biblioteka tr td:first-child img{
	max-width: 50px;
	max-height: 50px;
	margin-right: 20px;
}

.tabela-biblioteka a{
	text-decoration: underline;
}

.tabela-biblioteka tr td:last-child , .noWrapping{
		white-space: nowrap;
}

.gusbtn{
	min-width: 150px;
}

input#wyszukiwarka {
    margin-top: 5px;
}

.finishedTask.float-right {
    font-size: 16px;
    font-weight: 400;
	line-height: 24px;
}

/* klienci */

.nip-mask-loader {
	justify-content: center;
	width: 100%;
	height:38px;
    background-color: #ffffff9c;
    position: absolute;
    z-index: 500;
    align-items: center;
    display: flex;
}

/* mapy */

a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"] {
    display: none !important;
}
.gm-bundled-control .gmnoprint {
    display: block;
}
.gmnoprint:not(.gm-bundled-control) {
    display: none;
}

/* autocomplete */

.file-input {
	display:none;
} 

.autocomplete-item {
	z-index: 1000;
}
#role .custom-control-label {
	color: #787d8c !important;
	font-size: 10px !important;
	padding-top: 3px;
}

/* service class */

.service-status {
	width:40px;
	height:40px;
	border-radius:50px;
}	

.service-status.status-new {
	border:1px solid #28a745;
}

.service-status.status-complete {
	border:1px solid #28a745;
	background:#28a745;
	justify-content: center;
    align-items: center;
    display: flex;
}
.service-status.status-warning {
	background:#ffc107;
	border:1px solid #ffc107;
	border-color:#ffc107;
	justify-content: center;
    align-items: center;
    display: flex;
}


/* inspector */
.inspektor-czynnosci li{
    padding: 10px 0;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.inspektor-czynnosci li:last-child{
    border: 0 !important;
}

.inspektor-fazy a[aria-expanded="true"]{
    background-color: #f8f9fa;
}

.inspektor-fazy a[aria-expanded="true"] .show-more-arrow{
    transform: rotate(90deg);
}

.component-state{
    position:absolute;
    right:0;
}

.is-required {
	color: #da4141;
    font-size: 16px;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
	

	.landscape-rotate {
		size: 210mm 297mm; 
		/* Chrome sets own margins, we change these printer settings */
		/* margin: 0 -10mm;  */
		letter-spacing: -0.5px;
	}
	.landscape-rotate .table td, .landscape-rotate .table th{
		padding: 8px;
	}
	.landscape-rotate .table th{
		text-align: center;
	}
}

/* @page { size: landscape; } */