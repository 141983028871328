.loader{
    position: fixed;
    display: flex;
    height: 100%;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    background: rgb(255 255 255 / 85%);
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader-wrapper{
    display: flex;
    background: #ffffff;
    height: 100vh;
    position: fixed;
    z-index: 100;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.loader-wrapper img {
    width: 150px;
}