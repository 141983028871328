#wrapper-login{
	background-image: url('../gfx/fixlift-bg.jpg');
	background-position: top center;
  background-size: cover;
	height: 100vh;
}

.logoLogin{
	display: block;
    margin: 0 auto 50px auto;
    max-width: 40%;
	width:400px;
	min-width: 200px;
}

.container-login{
	background: #fff;
	position: relative;
    width: 60%;
    max-width: 60%;
    min-width: 285px;
	height: auto;
	margin: 20px auto;
    padding: 30px 0 10px;
}

.container-login span{
	display: block;
	max-width: 60%;
	min-width: 250px;
	margin: 20px auto;
}
.zaloguj-button{
	margin: 30px auto;
	display: block;
}

.has-float-label-login {
  display: block;
  position: relative;
}
.has-float-label-login  label, .has-float-label-login  > span {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
  top: -.5em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
}
.has-float-label-login label::after, .has-float-label-login > span::after {
  content: " ";
  display: block;
  position: absolute;
  background: white;
  height: 2px;
  top: 50%;
  left: -.2em;
  right: -.2em;
  z-index: -1;
}
.has-float-label-login .form-control::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}
.has-float-label-login .form-control::-moz-placeholder {
  opacity: 1;
  transition: all .2s;
}
.has-float-label-login .form-control:-ms-input-placeholder {
  opacity: 1;
  transition: all .2s;
}
.has-float-label-login .form-control::placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}
.has-float-label-login .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-float-label-login .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}
.has-float-label-login .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.has-float-label-login .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.has-float-label-login .form-control:placeholder-shown:not(:focus) + * {
  font-size: 100%;
  opacity: .5;
  top: 1.2em;
}

.input-group-login .has-float-label-login {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.input-group-login .has-float-label-login .form-control {
  width: 100%;
  border-radius: 8px;
    border: 2px solid rgba(0,0,0,.05);
}

.form-control-login{
	height: calc(2.5em + .75rem + 2px);
}

.errormsg-login{
	text-align: center;
	font-size: 14px;
	color: #BF1E2E;
	display: none;
}
.error-login label, .error > span{
color: #BF1E2E;
}